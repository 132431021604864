
import 'g/../css/app.css';

import trans from "g/Scripts/Translations.js";

import axios from 'axios';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import { createApp, h } from 'vue';
import { createInertiaApp, Link } from '@inertiajs/vue3';
import Layout from '@/Shared/Layout.vue';
import * as Sentry from "@sentry/vue";

let vueApp;
createInertiaApp({
  progress: {
    delay: 250,
    color: (import.meta.env.VITE_LOADING_BAR_COLOR ?? '#283747'),
    includeCSS: true,
    showSpinner: false,
  },
  resolve: async (name) => {
    const pages = import.meta.glob(['/resources/Custom/js/Pages/**/*.vue', '/resources/Global/js/Pages/**/*.vue'], { eager: false });
    const resolvePage = pages[`/resources/Custom/js/Pages/${name}.vue`] || pages[`/resources/Global/js/Pages/${name}.vue`];
    const page = await resolvePage();
    page.default.layout = page.default.layout || Layout;
    return page;
  },

  setup({ el, App, props, plugin }) {

    vueApp = createApp({ render: () => h(App, props) });

    vueApp.component('Link', Link);

    vueApp.use(trans, props.initialPage.props ?? {});

    vueApp.use(plugin);

    vueApp.mount(el);

    delete el.dataset.page;

    initSentry(vueApp);

    return vueApp;
  },
});


function initSentry(vueApp) {
  if (['production', 'stage'].includes(import.meta.env.VITE_APP_ENV)) {
    Sentry.init({
      app: vueApp,
      dsn: "https://2d0e2b2020c347968742976fbe44a4a1@o58588.ingest.sentry.io/6312722",
      ignoreErrors: [
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
        'TypeError: Cancelled',
        'Hydration completed but contains mismatches.',
        /ReferenceError:/,
      ],
    });
  }
}


if (import.meta.env.SSR === false)
{
  window.cookieConsent = localStorage.getItem('cookieConsent');
}
try {
  if (typeof window !== "undefined") {
    window.cookieConsent = localStorage.getItem('cookieConsent');
  }
} catch (error) {
  console.log("localstorage error");
}
