import { shallowRef, computed } from "vue";
import { usePage } from "@inertiajs/vue3";

const $page = usePage();

const cache = shallowRef({ cart: {}, timestamp: 0 });

const cart = computed(() => {
  if(cache.value.timestamp < $page.props.timestamp) {
    cache.value = { cart: $page.props.cart, timestamp: $page.props.timestamp };
  }
  return cache.value.cart;
});

function useCart() {
  return cart;
}

export {
  useCart,
};
